.ModalWrap{
    outline: none;
 }
 .ModalWrapBox{
     width: 100%;
     max-width: 500px;
     outline: none;
     // padding: 25px;
     margin:  0 auto;
     background-color: #fff;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%,-50%);
 }
 .modal{
    
     padding: 17px 0;
     border-bottom: 1px solid #dadbdc;
 }
 .modalHeader{
     padding: 0 25px;
     display: flex;
     align-items: center;
     justify-content: space-between;
 }
 .modalBox{
     padding:  25px;
 }
 .btn_standart{
     border-radius: 4px !important;
       background-color: #2e408a !important;
       color: #fff !important;
 }
 .titleWindow{
    font-size: 24px !important;
    font-weight: 500 !important;
 }