.TableManagers{
    // & tr{
    //     background-color: red;
    // }
}
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.TableManagers{
    & tbody{
        & tr{
            &:nth-child(even){
                background-color: #f4f4f5;
            }
        }
    }
}


.btn_standart{
    border-radius: 4px !important;
      background-color: #2e408a !important;
      color: #fff !important;
      margin-left: 15px;
}
.TextMessage{
    font-size: 20px !important;
    margin: 50px auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 560px) {
    .header{
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .MainTitle{
        margin-bottom: 20px !important;
    }
}