.OrdersEmpty{
    font-size: 20px !important;
    text-align: center !important;
}
.filterTitle{
    font-size: 16px !important;
    font-weight: bold !important;
    line-height: 1.5 !important;
    color: #6c6f77 !important;
}
.tableWrap{
    margin: 16px 0 !important;
}
.tableBox{
    // & tbody{
        & td{
            text-align: center;
        }
    // }
}