.Form_wrap{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-bottom: 16px;
  }
  .butoon__order_wrap{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .butoon__order{
    border-radius: 4px !important;
    background-color: #2e408a !important;
    color: #fff !important;
  }
  .input_order{
    & .input__box{
      margin-bottom: 0 !important;
    }
  }
  .btn_standart{
    border-radius: 4px !important;
      background-color: #2e408a !important;
      color: #fff !important;
      margin-left: 15px;
}
.btn_back{
    margin-right: 15px;
}
.InpunTitle{
    margin-bottom: 5px !important;
}
.password_box{
  display: grid;
  grid-template-columns: 400px;
  grid-gap: 16px ;
  margin-bottom: 16px;
}