.title{
    font-size: 18px;
}
.weight{
    font-weight: 700;
}
.author{
    font-size: 16px;
}
.text{
    background: #f9fafa;
    padding: 5px;
    border-radius: 10px;
    max-width: 85%;
    margin: 5px 0;
}
.main{
    display: flex;
    & form {
        width: 100%;
    }
}
.comentForm{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}
.group{
    margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
    .main_fail{
        flex-direction: column;
    }
  }
