.selectBox{
    max-width: 250px;
}
.selectWrap{
    display: grid;
    grid-template-columns: 230px 200px 140px;
    grid-column-gap: 15px;
    margin: 10px 0;
    margin-right: 15px;
}
.statusWrap{
 display: flex;
 align-items: center;
}
.filterWrap{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.statusWrapButton{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
    margin: 10px 15px;
}
.statusButton{
    border: 1px solid #dadbdc !important;
    border-radius: 16px  !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    padding: 5px 8px !important;
    line-height: 1.4 !important;
    transition: .3s !important;
    &:hover{
        transition: .3s !important;
        border: 1px solid #556f99 !important;
        color: #556f99 !important;
    }
}

.statusButtonActive{
    transition: .3s !important;
    border: 1px solid #556f99 !important;
    color: #556f99 !important;
}


.CleareButton{
    border: 1px solid #dadbdc !important;  
    font-size: 14px !important; 
    text-transform:   none !important;
    font-weight: 600 !important; 
    text-align: center !important; 
    color: #6b6e74 !important; 
}

@media (max-width: 992px){
    .selectWrap{
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(200px, 250px));
        width: 100%;
        grid-gap: 15px;
        margin: 10px 0;
        margin-right: 15px;
    }
}

@media (max-width: 560px){
    .statusWrap{
        flex-direction: column;
        align-items: baseline;
        width: 100%;
    }
    .statusWrapButton{
        grid-template-columns: repeat(auto-fill,minmax(120px,125px));
        grid-gap: 15px;
        margin: 15px 0;
        width: 100%;
    }
}