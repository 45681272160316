.input{
    position: relative;
     
}
.input__box{
    width: 100%;
}
.input_field{
    padding: 8px 12px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #dadbdc;
}
.input_field:focus{
    outline: none;
    border-color: #556f99;
}
.input__box_error{
    border-color: #eb3b13;
}
.input_error{
    position: absolute;
    bottom: -16px;
    font-size: 12px;
    color: #eb3b13;
}