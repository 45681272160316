.OrdersEmpty{
    font-size: 20px !important;
    text-align: center !important;
}
.filterTitle{
    font-size: 16px !important;
    font-weight: bold !important;
    line-height: 1.5 !important;
    color: #6c6f77 !important;
}
.tableWrap{
    margin: 16px 0 !important;
}
.tableWrapArchive{
    height: calc(100vh - 42px);
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}