

.Form_wrap{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-bottom: 16px;
   
}
.butoon__order_wrap{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.butoon__order{
  border-radius: 4px !important;
  background-color: #2e408a !important;
  color: #fff !important;
}
.InpunTitle{
  font-weight: 500 !important;
  margin-bottom: 5px !important;
}
.input_order{
  & .input__box{
    margin-bottom: 0 !important;
  }
}

  .first_order{
    display: grid;
    grid-template-columns: minmax(280px, 40%) 1fr;
    grid-gap: 65px;
  }
  .FormOrderStart{
    display: grid;
    grid-gap: 16px;
  }
  .first_order__btn{
    background-color: #2e408a !important;
    color: #fff !important;

}


  @media (max-width: 1200px) {
    .first_order{
        grid-gap: 35px;
      } 
  }
  @media (max-width: 992px) {
    .first_order{
        grid-template-columns: 1fr;
    }
  }

  .input{
      
  }