.loadText{
    font-size: 22px;
    margin-bottom: 20px;
}
.loader{
    position: relative;
    padding: 20px 0;
}
.tableMain{
    // position: relative;
    // overflow: auto;

}
.tableBox{
    // position: relative;
    // overflow: auto;
}
.wrapLoad{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
}
 
.wrapIsLoading{
    display: flex;
    flex-direction: column;
}
.wrapLoaderBox{
    position: relative;
    height: 40px;
    width: 40px;
}
