.form_wrap{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-bottom: 16px;
     
  }
  .butoon__order_wrap{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .butoon__order{
    border-radius: 4px !important;
    background-color: #2e408a !important;
    color: #fff !important;
    font-weight: 600 !important;
    
  }
  .buttonBack{
    margin-right: 25px !important;
  }

  @media only screen and (max-width: 520px)  {
    .butoon__order_wrap{
      flex-direction: column-reverse;
    }
    .buttonBack{
      margin-top: 15px !important;
      margin-right: 0 !important;
    }

  }
  .buttonDisable{
    background-color: #abb3d0 !important;
    font-weight: 600 !important;
     
    line-height: 1.5 !important; 
    color: #ffffff !important;
  }