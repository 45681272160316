.table_wrap{
  overflow-x: auto;
  width: 100%;
  padding: 10px 0;
  display: block;
  position: relative;
}
.table_box{
    width: 100%;
    min-width: 900px;
    margin: 10px 0 0;
}
.ModalWrap{
  background-color: #fff;
  max-width: 450px;
  margin: 0 auto;
}

.fotterControl{
  padding: 10px 0;
  background-color: #f4f4f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.NextStep{
  border-radius: 4px !important;
    background-color: #2e408a !important;
    color: #fff !important;
    // margin:0 20px !important;
}
.orderStep{
  min-width:   170px !important;
  border-radius: 4px !important;
    background-color: #2e408a !important;
    color: #fff !important;
    // margin:0 20px !important;
}
.fotterControlText{
  margin-right: 10px !important;
}

@media (max-width: 950px) {
  .fotterControl{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .orderStep{
    margin-top: 10px !important;
  }
  .NextStep{
    margin-top: 10px !important;
  }
}

// @media (max-width: 980px) {
//     .table_wrap{
//         width: 550px;
//     }
//   }
// @media (max-width: 880px) {
//     .table_wrap{
//         width: 500px;
//     }
//   }
// @media (max-width: 640px) {
//     .table_wrap{
//         width: 500px;
//     }
//   }
// @media (max-width: 540px) {
//     .table_wrap{
//         width: 400px;
//     }
//   }
// @media (max-width: 440px) {
//     .table_wrap{
//         width: 300px;
//     }
//   }