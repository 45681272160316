.TableManagers{
    // & tr{
    //     background-color: red;
    // }
}
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn_standart{
    border-radius: 4px !important;
      background-color: #2e408a !important;
      color: #fff !important;
      margin-left: 15px;
}


@media (max-width: 430px ) {
    .header{
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
    }
     .MainTitle{
         margin-bottom: 10px !important;
     }
}
