.orderStatus{
    font-size: 16px !important;
    font-weight: bold !important;
    line-height: 1.5 !important; 
    color: #2fa372 !important;
}
.orderTitle{
    font-size: 32px;
    line-height: 1.41; 
    color: #262729;
}
.orederHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footerTable{
    background-color: #f4f4f5;
    padding-bottom: 5px;
    & td{
        font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #262729;
    }
}
.tdPrise{
    font-size: 16px !important;
    font-weight: bold !important;
    line-height: 1.5 !important;
    color: #262729 !important;
}
.orederMain{
    // & tbody{
        & td{
            text-align: center;
        }
    // }
}
 
.orderCansleOrder{
    border-radius: 4px !important;
    background-color: #e6353b !important;
    padding: 8px 12px !important;
    color: #fff !important;
    font-weight: 600;
    margin: 0 20px !important;
    transform: scale(1);
    transition: .3s;
    &:hover{
        transform: scale(1.05);
        transition: .3s;
    }
}
.orderBuy{
    border-radius: 4px !important;
    background-color: #2e408a !important;
    padding: 8px 12px !important;
    color: #fff !important;
    font-weight: 600 !important;
    margin: 0 20px !important;
    transform: scale(1);
    transition: .3s;
    &:hover{
        transform: scale(1.05);
        transition: .3s;
    }
}
.orderBuyDisable{
    border-radius: 4px !important;
    background-color: #abb3d0 !important;
    padding: 8px 12px !important;
    color: #fff !important;
    font-weight: 600 !important;
    margin: 0 20px !important;
    transform: scale(1);
    transition: .3s;
}
.table {
    &  tbody tr{
      & td {
        border-bottom: 1px solid #eee !important;

      }
    }
  }

.manager{
    text-align: left;
}



@media screen and (max-width: 1230px) {
     .orederHeader{
         flex-direction: column;
     }
     .orederHeaderControl{
         margin: 20px 0;
         display: flex;
         align-items: center;
         justify-content: space-between;
         width: 100%;
     }
     .orderBuy,.orderCansleOrder{
         margin: 0 !important;
     }
  }
@media screen and (max-width: 490px) {
     .orederHeader{
         flex-direction: column;
     }
     .orderTitle{
         font-size: 24px;
     }
     .orederHeaderControl{
         flex-direction: column;
     }
     .orderBuy,.orderCansleOrder{
         margin: 10px 0 !important;
     }
  }