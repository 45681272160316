html, body{
    margin:0;
    padding:0;
    height:100%;
  }
  section {
    position: relative;
    border: 1px solid #000;
    padding-top: 37px;
    background: #500;
  }
  section.positioned {
    position: absolute;
    top:100px;
    left:100px;
    width:800px;
    box-shadow: 0 0 15px #333;
  }
  .container {
    overflow-y: auto;
    height: 200px;
  }
  table {
    border-spacing: 0;
    width:100%;
  }
//   td + td {
//     border-left:1px solid #eee;
//   }
//   td, th {
//     border-bottom:22px solid #000;
//     color: #000;
//     padding: 10px 25px;
//   }
td {
    border-bottom: 1px solid #eee;
    font-size: 14px;
    line-height: 1.5;
    color: #262729 !important;
    font-family: Open Sans,sans-serif ;

    padding: 10px 5px;

}
  th {
    padding: 10px 8px;
    height: 0;
    line-height: 0;
    padding-top: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #d5d9e8;
    white-space: pre;
    text-align: inherit;
}

.managerBox{
  display: flex;
  align-items: center;
}
.linkManager{
  font-weight: 600;
  color: #5866a1;
  cursor: pointer;
  &:hover{
    transition: .3s;
    color: #828cb9;
  }
}
.avatarManager{
  width: 40px;
  height: 40px;
  margin-right: 10px;

  transition: .3s;
  
}
.colomMedium{ 
  white-space: normal;
  line-height: 1;
}