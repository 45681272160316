.toggleClass{
    padding: 5px !important;
} 
.toggleTd{
    border: none !important;
}
.toggleOrderId{
    border: none !important;
}
.buttonViewsMessage{
    position: relative;
}
.newMessageMark{
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50px;
    right: 0;
    top: 0; 
}
.newMessageMark_visible{
    display: block;
}
.hidden_newMessageMark{
    display: none !important;
}
tbody tr{
    // &:nth-child(even){
    //     background-color:  #f4f4f5;
    // }
    
    & td:nth-child(1) {
         border: none !important;
      }
    & td:nth-child(2) {
       border: none !important;
      }
     
}
.btn_delete{
    border-radius: 4px !important;
    background-color: #e6353b !important;
    padding: 8px 12px !important;
    color: #fff !important;
    font-weight: 600;
    margin: 0 20px !important;
    transform: scale(1);
    transition: .3s;
    &:hover{
        transform: scale(1.05);
        transition: .3s;
    }
}
.btn_continue{
    border-radius: 4px !important;
    background-color: #2e408a !important;
    padding: 8px 12px !important;
    color: #fff !important;
    font-weight: 600 !important;
    margin: 0 20px !important;
    width: 195px;
    transform: scale(1);
    transition: .3s;
    &:hover{
        transform: scale(1.05);
        transition: .3s;
    }
}
.orderRegardedBox{
    background-color: #d8f4e8;
    margin: 0 auto;
    width: 100%;
}
.orderRegarded{
    display: flex;
    align-items: center;
}

.foterInfoBox{
    background-color: #dadbdc;
    padding: 0 !important;
    margin-bottom: 3px;
}
.foterInfoLine{
    display: flex;
    align-items: center !important;
    padding: 10px 30px;
}
.foterInfoText{
  line-height: 1.5; 
  color: #262729;
}
.alignCenter{
    text-align: center;
}

.linkManager{
    font-size: 14px !important;
      min-width: 135px !important;
    font-weight: 600;
    color: #5866a1;
    cursor: pointer;
    &:hover{
      transition: .3s;
      color: #828cb9;
    }
  }
  .manger{
      text-align: left;
      max-width: 200px;
  }