.seting {
    padding: 0;
    background-color: #f9fafa !important;
    line-height: 1;
    color: #5866a1;
    font-size: 14px;
    font-weight: 600;
    transition:.3s;
    &:hover{
        color:#828cb9;
        transition: .3s;
      transform:rotate(0deg);
      & .MuiButton-startIcon{
        transform:rotate(45deg);
        transition:.6s;
        color: #828cb9;
      }
    }
  }
.buttonDisable{
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.12) !important;
    &:hover{
        border: 1px solid rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.12) !important;
        background-color: transparent !important;
        cursor: default !important;
    }
    &:focus{
        border: 1px solid rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.12) !important;
        background-color: transparent !important;
        cursor: default !important;
    }
}
  .ModalWrap{
    background-color:#fff;
    max-width: 500px;
    margin: 0 auto;
    display: flex
  }