.OrdersEmpty{
    font-size: 20px !important;
    text-align: center !important;
}
.filterTitle{
    font-size: 16px !important;
    font-weight: bold !important;
    line-height: 1.5 !important;
    color: #6c6f77 !important;
}
.tableWrap{
    margin: 16px 0 !important;
   
}
.tableBox{
    // position: relative;
    // overflow: auto;
}
// .tableWrap{
//     min-height: 300px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

// .FilterWrapBox{
//     minHeight: calc(100% - 42px - 48px - 48px);
// }