.selectStatus{
    min-width: 180px;
    &:before,&:after{
        display: none;
    }
    
}
.selectBox{
    min-width: 140px;
    &:before,&:after{
        display: none;
    }
    & .MuiInputBase-root{
        &:before,&:after{
            display: none !important;
        }
    }
    & .MuiInput-underline:before,&  .MuiInput-underline:after{
        display: none !important;
    }
}

.valuating{
    color: #faad14 !important;
    font-weight: bold !important;
    line-height: 1.71 !important;
}
 
.declined{
    color: #c11e02 !important;
    font-weight: bold !important;
    line-height: 1.71 !important;
}
 
.valuated{
    color: #2fa372 !important;
    font-weight: bold !important;
    line-height: 1.71 !important;
}
.finished{
    color: #2fa372 !important;
    font-weight: bold !important;
    line-height: 1.71 !important;
}
.unordered{
    color: #999999 !important;
    font-weight: bold !important;
    line-height: 1.71 !important;
}


.in_progress{
    color: #2e408a !important;
    font-weight: bold !important;
    line-height: 1.71 !important;
}
.agreed_without_contract{
    color: #cd5700 !important;
    font-weight: bold !important;
    line-height: 1.71 !important;
}
.contract_sent{
    
    color: #55AAFF !important;
    font-weight: bold !important;
    line-height: 1.71 !important;
}
.unactive{
    color: #999999 !important;
    font-weight: bold !important;
    line-height: 1.71 !important;
}
.agreed{

    color:   #159033 !important;
    font-weight: bold !important;
    line-height: 1.71 !important;
  
}