
.newMessageMark{
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50px;
    right: 0;
    top: 0; 
  }
  .newMessageMark_visible{
    display: block;
  }
  .hidden_newMessageMark{
    display: none !important;
  }