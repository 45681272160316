.MainTitle{
    color: #26272a
}
.SubMainTitle{
    color: #262729,
  }
//  .{
//     overflow: auto;
//  }
.btn_standart{
    border-radius: 4px !important;
      background-color: #2e408a !important;
      color: #fff !important;
}