.loadText{
    font-size: 22px;
    margin-bottom: 20px;
    position: relative;
}
.loader{
    position: relative;
    padding: 20px 0;
}
// .tableMain{
//     position: relative;
//     overflow: auto;
// }
.wrapLoad{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    position: relative;
}
.wrapIsLoading{
    display: flex;
    flex-direction: column;
}
.wrapLoaderBox{
    position: relative;
    height: 40px;
    width: 40px;
}


.OrdersEmpty{
    font-size: 20px !important;
    text-align: center !important;
}
.filterTitle{
    font-size: 16px !important;
    font-weight: bold !important;
    line-height: 1.5 !important;
    color: #6c6f77 !important;
}
.tableWrap{
    margin: 16px 0 !important;
   
}
 

.headerInfo{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pageTitle{
    font-size: 32px;
    line-height: 1.41; 
    color: #262729;
}
.managerName{
    font-size: 24px;
    line-height: 1.5;
    color: #262729;
}

.Header{
    margin: 16px 0;
}

.buttonDownload{
   
    border: solid 1px #dadbdc  !important;
    background-color: rgba(255, 255, 255, 0) !important;
    text-transform: none !important;
    &:hover{
        text-decoration: none;
        background-color: rgba(108, 110, 117, 0.04) !important;
 
    }
   
}

.textButton{
    margin-left: 7px !important;
}

@media (max-width: 872px) { 
    .headerInfo{
        flex-direction: column;
        align-items: baseline;
    }
    .buttonDownload{
        margin-top: 30px !important;
    }
 }