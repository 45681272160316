.auth {
  position: relative;
  height: calc(100vh - 42px);
  overflow: hidden;
}

.auth_main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 102px);
}

.auth_main_form {
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  background-color: #fff;
  padding: 25px 45px;
  box-sizing: border-box;

  @media (max-width: 600px) {
  padding: 25px 35px;
  }
}

.auth_bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  }
}