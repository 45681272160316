.upload_file{
    width: 1px;
    height: 1px;
    opacity: 0;
}
.fileName{
    margin: 10px 0;
    display: flex;
    align-items: center;
    & svg{
        margin-left: 10px;
    }
}