.card{
    display: inline-block;
    padding: 20px 25px;
    overflow: initial !important;
}
.cardTitle{
    line-height: 1;
    margin-bottom: 15px !important;
    font-size: 20px !important;
    font-weight: bold !important;
    color: #262729;
}
.cardName{
    font-weight: bold !important;
    line-height: 1.71;
}
.cardMailLink{
    text-decoration: none;
}
.cardMail{
    color: #6c6e75;
    text-decoration: none;
    line-height: 1.7 !important;
    transition: .3s;
    &:hover{
        transition: .3s;
        color: #556f99;
    }
}
.cardButtonLink{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #5866a1;
    transition: .3s;
    line-height: 1.7;
    cursor: pointer;
    & svg{
        width: 15px;
        margin-right: 10px;
    }
    & p{
        text-decoration: none;
        font-weight: 600 !important;
    }
    &:hover{
        transition: .3s;
        color: #828cb9;
        
    }
}
