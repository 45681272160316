.seting{
    color: #5866a1;
    padding:0 6px !important;
    // font-size: 14px;
    transition: .3s;
    font-weight: 600 !important;
    line-height: 1;
    background-color: #f9fafa !important;
    &:hover{
        color: #828cb9;
        transform: rotate(0deg);
        transition: .3s;
        & .MuiButton-startIcon{
            transform:rotate(45deg);
            transition:.6s;
            color: #828cb9;
        }
    }
     
}
a{
    text-decoration: none !important;
}
 .infoUser{
    font-size: 14px;
    line-height: 1.71;
    color: #6c6e76;
    margin: 4px 0 !important;
 }
 .infoUserSetings{
     padding: 0 8px;
 }
.linkMail{
    color: #6c6e75;
    text-decoration: none;
    transition: .3s;
    display: block;
    margin-top: 5px;
    &:hover{
        transition: .3s;
        color: #556f99;
    }
}