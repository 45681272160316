
.btn_standart{
    border-radius: 4px !important;
    background-color: #2e408a !important;
    color: #fff !important;
}

.window{
    max-width: 450px;
    margin: 0 auto;
    background-color: #fff;
    outline: none;
}
.windowHeader{
    padding: 16px 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dadbdc;

}


.windowHeaderTitle{
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    color: #262729;
}
.windowMain{
    padding: 13px 22px;
}
.windowMainTitle{
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #262729;
}
.windowMainUser{
    padding: 10px 0;
}
.windowMainUserName{
    font-size: 14px;
    line-height: 1.5;
    color: #262729;
}
.windowMainCompanyName{
    font-size: 12px;
    color: #6c6e75;
}
.windowInfo{
    margin: 10px 22px;
    background: #f4f4f5;
    padding: 10px 22px;
}
.windowInfoPrice{
    font-size: 14px;
    line-height: 1.5;
    color: #262729;
}
.windowInfoPriceValue{
    font-weight: bold;
}
.windowFormMain{
    padding: 16px 22px;
    border-bottom: 1px solid #dadbdc;

}
.windowFormfooter{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 22px;
}

.windowBox{
    min-height: 100vh;
    display: flex;
    align-items: center;
}
.back{
    margin-right: 15px !important;
}