.btn_standart{
    border-radius: 4px !important;
      background-color: #2e408a !important;
      color: #fff !important;
      margin-left: 15px;
}
.TableManagers{
    & tbody{
        & tr{
            border: none;
            &:nth-child(even){
                background-color: #f4f4f5;
            }
            & td{
                border: none;
            }
        }
    }
}

.btn_remove{
    border-radius: 4px !important;
    background-color: #e6353b !important;
    color: #fff !important;
    padding: 0px 5px !important;
    outline: none !important;
    box-sizing: border-box !important;
    border: none !important;
}
.btn_standart{
    border-radius: 4px !important ;
      background-color: #2e408a  !important;
      color: #fff !important !important;
      padding: 0px 5px !important;
      outline: none !important;
      box-sizing: border-box !important;
      border: none !important;
      margin-left: 15px  !important;;
}