.fullName{
    font-size: 14px !important;
  line-height: 1.5 !important;
  color: #262729 !important;
}
.companyName{
    font-size: 12px !important;
    line-height: 2 !important;
    color: #6c6e75 !important;
}
.userInfo{
    min-width: 190px !important;
    text-align: left;
  }

  .newMessageMark{
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50px;
    right: 0;
    top: 0; 
}
.newMessageMark_visible{
    display: block;
}
.hidden_newMessageMark{
    display: none !important;
}