.TextareaInput{
    position: relative;
     
}
.TextareaInput__box{
    width: 100%;
    /* margin-bottom: 16px; */
}
.TextareaInput_field{
    padding: 8px 12px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #dadbdc;
    resize:none;
    font-size: 14px;
}
.TextareaInput_field:focus{
    outline: none;
    border-color: #556f99;
}
.TextareaInput__box_error{
    border-color: #eb3b13 !important;
}
.TextareaInput_error{
    position: absolute;
    bottom: -16px;
    font-size: 12px;
    color: #eb3b13 !important;
}