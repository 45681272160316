.input__box_error{
    border-color: #eb3b13 !important;
}
.input_error{
    position: absolute;
    bottom: -16px;
    font-size: 12px;
    color: #eb3b13 !important;
}
.wrapSelect{
    position: relative;
}

.inputSelect{
    border:none;
    &+div{
        background-color: hsl(0,0%,100%);
        border-color: hsl(0,0%,80%);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px; 
    }
}
.inputSelect__box_error{
    border: 1px solid red;
    border-radius: 4px;
    & div{
        border:none;
    }
}