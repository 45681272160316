.userInfo{
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: 130px;
    & .name{
        font-size: 14px; 
        
    }
    & .phone{
        font-size: 14px;
        color: #262729;
    }
}

.link{
    color: #5866a1;
        transition: .3s;
        &:hover{
            color: #828cb9;
            transition: .3s;
        }
}