.input_field{
    padding: 8px 12px;
    width: 100%;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #dadbdc;
}
.input_field:focus{
    outline: none;
    border-color: #556f99;
}
.input__box_error{
    border-color: #eb3b13;
}
.input_error{
    position: absolute;
    bottom: -16px;
    font-size: 12px;
    color: #eb3b13;
}
.MuiPickersCalendarHeader-switchHeader{
    border-bottom:1px solid rgba(0, 0, 0, 0.09) ;
  
}
.MuiPickersCalendarHeader-transitionContainer p{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #36373a;
}
.MuiPickersCalendarHeader-dayLabel{
      font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57 !important;
  letter-spacing: normal;
  text-align: center !important;
  color: #36373a !important;
}
#dataPicer{
    font-size: 14px;
    line-height: 1.5;
    color: #26272a;
}
.input_dataPicker{
    height: 100%;
    &:hover{
        outline: none;
        // border-color: #f1d1f1 !important;
    }
     & .MuiFormControl-root{
         height: 100%;
        &:hover{
            outline: none;
            // border-color: #a1d1f1 !important;
        }
         & .MuiInputBase-root{  
            // border: 10px solid yellow !important;
            &::before,&::after{
                display: none;
            }
            &:hover{
                outline: none;
                // border-color: #e1e1f1 !important;
            }
            & #dataPicer{
                padding: 8px 12px;
                
               
                width: 100%;
                font-size: 14px;
                border-radius: 4px;
                border: 1px solid #dadbdc !important;
                transition: .3s;
                &:hover{
                    outline: none;
                    transition: .3s;
                    border-color: #556f99 !important;
                    cursor: pointer;
                }
            }
        }
    }

}
.inputFilterdataPicker{
    width: 100%;
    & .MuiFormControl-root{
        width: 100%;
        & .MuiInputBase-root{  
            width: 100%;
            & #dataPicer{
                width: 100%;
                height: 38px;
                box-sizing: border-box;
            }
        }
    }
}
.input_dataPicker_plachholder{
    & .MuiFormControl-root{
        & .MuiInputBase-root{  
            & #dataPicer{
                color: #6c6e75 !important;
            }
        }
    }
}

.dataPicer{
   
    & .MuiInputBase-root{  
       
        & .MuiInputBase-input{
            padding: 8px 12px;
            width: 100%;
            font-size: 14px;
            border-radius: 4px;
            border: 21px solid #dadbdc !important;
        }
    }
}